import "./style.css";

export default function CleaningDayStatisticsBadge(props) {
    const {
        averageApartmentsPerCleaner,
    } = props;

    return (
        <div className="cleaning-day-statistics-badge-container">
            {averageApartmentsPerCleaner.toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}
        </div>
    );
}