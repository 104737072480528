import { gql, useQuery } from "@apollo/client";
import { Select } from "antd";
import { fuzzySearch } from "common/common";
import { useCallback, useEffect } from "react";

const QUERY = gql`
    query GetApartmentsAndStoragesForJobLocationSelect {
        apartments(filter: {active: true}) {
            id
            name
        }
        storages {
            id
            name
        }
    }
`;

export default function JobLocationSelect(props) {
    const {
        value,
        onChange,
        ...otherProps
    } = props;

    const { data, loading, error } = useQuery(QUERY);
    const multiple = otherProps?.mode === 'multiple';

    const getValue = useCallback(value => {
        if (multiple) {
            return [...value ?? []].map(item => {
                if (item?.storageId) {
                    return `storage:${item.storageId}`;
                }
                if (item?.apartmentId) {
                    return `apartment:${item.apartmentId}`;
                }
                return null;
            });
        }
        else {
            if (value?.storageId) {
                return `storage:${value.storageId}`;
            }
            if (value?.apartmentId) {
                return `apartment:${value.apartmentId}`;
            }
            return null;
        }
    }, [multiple]);

    const handleOnChange = useCallback(value => {
        if (multiple) {
            onChange([...value ?? []].map(item => parseKey(item)));
        }
        else {
            onChange(parseKey(value));
        }
    }, [multiple, onChange]);

    useEffect(() => {
        if (data) {
            const possibleKeys = [
                ...data.apartments.map(apartment => `apartment:${apartment.id}`),
                ...data.storages.map(storage => `storage:${storage.id}`),
            ];
            if (multiple) {
                const valueKeys = getValue(value);
                const inexistentKeys = valueKeys.filter(key => !possibleKeys.includes(key));
                if (inexistentKeys.length > 0) {
                    handleOnChange(valueKeys.filter(key => !inexistentKeys.includes(key)));
                }
            }
            else {
                const valueKey = getValue(value);
                if (!possibleKeys.includes(valueKey)) {
                    handleOnChange();
                }
            }
        }
    }, [data, value, multiple, getValue, handleOnChange]);

    function parseKey(key) {
        if (key) {
            if (key.startsWith('storage:')) {
                const storageId = key.replace('storage:', '');
                return { storageId };
            }
            if (key.startsWith('apartment:')) {
                const apartmentId = key.replace('apartment:', '');
                return { apartmentId };
            }
        }
        else {
            return undefined;
        }
    }

    if (loading) {
        return (
            <Select
                loading
                disabled
            />
        );
    }

    if (error) {
        return (
            <Select
                statuss="error"
                disabled
            />
        );
    }

    return (
        <Select
            options={[
                {
                    label: 'Apartments',
                    options: [...data?.apartments ?? []]
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map(apartment => ({
                            label: apartment.name,
                            value: `apartment:${apartment.id}`,
                        }))
                },
                {
                    label: 'Storages',
                    options: [...data?.storages ?? []]
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map(storage => ({
                            label: storage.name,
                            value: `storage:${storage.id}`,
                        }))
                },
            ]}
            value={getValue(value)}
            onChange={value => handleOnChange(value)}
            showSearch
            filterOption={(input, option) => fuzzySearch(option.label, input)}
            {...otherProps}
        />
    );
}