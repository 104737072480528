import { Col, Form, Row, Switch } from "antd";
import { useEffect } from "react";
import UserGroupSelect from "user-groups/components/user-group-select/UserGroupSelect";

export default function OrganizationCleaningForm(props) {
    const {
        form,
        organization,
        ...otherProps
    } = props;

    useEffect(() => {
        if (form) {
            form.setFieldsValue({
                cleaningUserGroupIds: organization?.cleaningUserGroups?.map(group => group.id),
                cleaningDirtyOnLastBeforeUnavailableForRent: organization?.cleaningDirtyOnLastBeforeUnavailableForRent ?? false,
                cleaningDirtyOnFirstDuringUnavailableForRent: organization?.cleaningDirtyOnFirstDuringUnavailableForRent ?? false,
                cleaningDirtyOnLastDuringUnavailableForRent: organization?.cleaningDirtyOnLastDuringUnavailableForRent ?? false,
                cleaningDirtyOnFirstAfterUnavailableForRent: organization?.cleaningDirtyOnFirstAfterUnavailableForRent ?? false,
                cleaningDirtyOnLastBeforeUnavailable: organization?.cleaningDirtyOnLastBeforeUnavailable ?? false,
                cleaningDirtyOnFirstDuringUnavailable: organization?.cleaningDirtyOnFirstDuringUnavailable ?? false,
                cleaningDirtyOnLastDuringUnavailable: organization?.cleaningDirtyOnLastDuringUnavailable ?? false,
                cleaningDirtyOnFirstAfterUnavailable: organization?.cleaningDirtyOnFirstAfterUnavailable ?? false,
            });
        }
    }, [form, organization]);

    return (
        <Row gutter={[16, 16]}>
            <Col span={24}>
                <Form
                    form={form}
                    labelWrap
                    {...otherProps}
                >
                    <Form.Item
                        name="cleaningUserGroupIds"
                        label="Cleaning user groups"
                    >
                        <UserGroupSelect
                            mode="multiple"
                        />
                    </Form.Item>
                    <Form.Item
                        name="cleaningDirtyOnLastBeforeUnavailableForRent"
                        label="Apartment is dirty on last day before unavailable for rent starts"
                        valuePropName="checked"
                    >
                        <Switch />
                    </Form.Item>
                    <Form.Item
                        name="cleaningDirtyOnFirstDuringUnavailableForRent"
                        label="Apartment is dirty on first day after unavailable for rent starts"
                        valuePropName="checked"
                    >
                        <Switch />
                    </Form.Item>
                    <Form.Item
                        name="cleaningDirtyOnLastDuringUnavailableForRent"
                        label="Apartment is dirty on last day before unavailable for rent ends"
                        valuePropName="checked"
                    >
                        <Switch />
                    </Form.Item>
                    <Form.Item
                        name="cleaningDirtyOnFirstAfterUnavailableForRent"
                        label="Apartment is dirty on first day after unavailable for rent ends"
                        valuePropName="checked"
                    >
                        <Switch />
                    </Form.Item>
                    <Form.Item
                        name="cleaningDirtyOnLastBeforeUnavailable"
                        label="Apartment is dirty on last day before blocked period"
                        valuePropName="checked"
                    >
                        <Switch />
                    </Form.Item>
                    <Form.Item
                        name="cleaningDirtyOnFirstDuringUnavailable"
                        label="Apartment is dirty on first day of blocked period"
                        valuePropName="checked"
                    >
                        <Switch />
                    </Form.Item>
                    <Form.Item
                        name="cleaningDirtyOnLastDuringUnavailable"
                        label="Apartment is dirty on last day of blocked period"
                        valuePropName="checked"
                    >
                        <Switch />
                    </Form.Item>
                    <Form.Item
                        name="cleaningDirtyOnFirstAfterUnavailable"
                        label="Apartment is dirty on first day after blocked period"
                        valuePropName="checked"
                    >
                        <Switch />
                    </Form.Item>
                </Form>
            </Col>
        </Row>
    );
}
