import { gql, useQuery } from "@apollo/client";
import { Alert, PageHeader, Result } from "antd";
import { useAuth } from "auth";
import { contains } from "common/common";
import CleaningDayStatisticsBadge from "cleaning-new/components/cleaning-day-statistics-badge/CleaningDayStatisticsBadge";
import UserGroupsAvailabilityCalendar from "users/components/user-group-availability-calendar/UserGroupAvailabilityCalendar";
import { addDays, isEqual, startOfToday } from "date-fns";

const QUERY = gql`
    query GetDataForCleaningAvailability($dateFrom: Date!, $dateTo: Date!) {
        organization(organizationId: "self") {
            id
            cleaningUserGroups {
                id
                users {
                    id
                    availability (filter: {dateFrom: $dateFrom, dateTo: $dateTo}) {
                        id
                        date
                        availability
                    }
                }
            }
            userAvailabilityTypes {
                id
                availability
                isAvailable
            }
            userGroups {
                id
                userAvailabilityTypes {
                    id
                    availability
                    isAvailable
                }
            }
        }
        cleaningStatistics (filter: {dateFrom: $dateFrom, dateTo: $dateTo}) {
            date
            apartmentsCount
        }
    }
`;

export default function DisplayCleaningAvailabilityView() {
    const dateFrom = startOfToday();
    const dateTo = addDays(startOfToday(), 28);

    const { permissions } = useAuth();
    const hasPermissions = contains(permissions, ['user:get:self', 'user_group:get', 'user_availability:get:self']);

    const { data, loading, error } = useQuery(QUERY, {
        variables: {
            dateFrom,
            dateTo,
        },
        skip: !hasPermissions,
    });

    if (!hasPermissions) {
        return (
            <Result
                status="error"
                title="No permissions"
                subTitle="You don't have permissions to view cleaners availability"
            />
        );
    }

    const availableAvailabilityTypes = [
        ...[...data?.organization?.userAvailabilityTypes ?? []],
        ...[...data?.organization?.userGroups ?? []].map(userGroup => userGroup.userAvailabilityTypes).flat(),
    ]
        .filter(availabilityType => availabilityType.isAvailable)
        .map(availabilityType => availabilityType.availability);

    function getUserAvailabilityOnDate(user, date) {
        return user.availability.find(item => isEqual(item.date, date));
    }

    function getAvailableCleanersCountOnDate(date) {
        const availableCleaners = [...data?.organization?.cleaningUserGroups ?? []]
            .map(userGroup => userGroup.users)
            .flat()
            .filter(user => {
                const availability = getUserAvailabilityOnDate(user, date)?.availability;
                return availability && availableAvailabilityTypes.includes(availability);
            })

        return availableCleaners.length;
    }

    function getCleaningApartmentsCountOnDate(date) {
        return [...data?.cleaningStatistics ?? []]
            .find(statistic => isEqual(statistic.date, date))
            .apartmentsCount;
    }

    function getAverageApartmentsPerCleaner(date) {
        const cleanersCount = getAvailableCleanersCountOnDate(date);
        const cleaningApartmentsCount = getCleaningApartmentsCountOnDate(date);

        if (cleanersCount === 0) {
            return 0;
        }

        return cleaningApartmentsCount / cleanersCount;
    }

    return (
        <PageHeader
            title="Cleaners availability"
        >
            {error && (
                <Alert
                    type="error"
                    showIcon
                    message="Failed to load cleaning user groups"
                />
            )}
            {!loading && data && (
                <UserGroupsAvailabilityCalendar
                    dateFrom={dateFrom}
                    dateTo={dateTo}
                    userGroupIds={data.organization.cleaningUserGroups.map(userGroup => userGroup.id)}
                    columnHeaderHeight={85}
                    renderDateHeaderExtra={date => (
                        <CleaningDayStatisticsBadge
                            averageApartmentsPerCleaner={getAverageApartmentsPerCleaner(date)}
                        />
                    )}
                />
            )}
        </PageHeader>
    );
}